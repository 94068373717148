import logo from './Images/Logo.png';
import homeBuilding from './Images/Building1.jpg'
import aboutImage from './Images/About.jpg'
import security from './Images/security.png'
import hours from './Images/24-hours.png'
import cake from './Images/cake.png'
import wifi from './Images/wifi.png'
import garden from './Images/Garden.jpg'
import dining from './Images/dining.jpg'
import food from './Images/food.jpg'
import room from './Images/room2.jpg'
import hospitality from './Images/hospitility.jpg'
import './App.css';
import React from 'react';
import { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set, onValue } from 'firebase/database';
import firebaseConfig from './firebaseConfig';
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut, setPersistence, browserSessionPersistence } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import Typed from 'typed.js';


// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth();
const provider = new GoogleAuthProvider();

function App() {

  return (
    <>
      <NavBar />
      <Home />
      <br />
      <About />
      <br /><br />
      <Services />
      <br /><br /><br />
      <Explore />
      <br /><br />
      <ReviewSection />
      <br />
      <br />
      <Footer />
    </>
  );
}

// Navigation Bar Here
function NavBar() {

  const displayMenu = () => {
    let a = document.getElementById('mobileNav');
    if (a.style.display == 'block') {
      a.classList.remove('animate__bounceInDown');
      a.classList.add('animate__bounceOutUp');
      a.style.display = 'none';
    } else {
      a.style.display = 'block';
      a.classList.remove('animate__bounceOutUp');
      a.classList.add('animate__bounceInDown');
    }

  }

  return (
    <>
      <nav id='navbar'>
        <div>
          <img src={logo} id='logo' alt='Logo' />
        </div>
        <div id='navContainer'>
          <ul>
            <li><a href='#home'>Home</a></li>
            <li><a href='#about'>About</a></li>
            <li><a href='#services'>Services</a></li>
            <li><a href='#explore'>Explore</a></li>
            <li><a href='#footer'>Contact</a></li>
          </ul>
        </div>
        <span onClick={displayMenu} class="material-symbols-outlined" id='menuButton'>&#8801;</span>
      </nav>
      {/* Mobile Navigation Goes Here */}
      <ul class='animate__animated' id='mobileNav'>
        <li><a href='#home'>Home</a></li>
        <li><a href='#about'>About</a></li>
        <li><a href='#services'>Services</a></li>
        <li><a href='#explore'>Explore</a></li>
        <li><a href='#footer'>Contact</a></li>
      </ul>
    </>
  );
}

//Home Here
function Home() {

  const el = React.useRef(null);

  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ['Feel like HOME.', 'Hotel for Everyone.', 'Where Comfort Meets Class.', 'Unforgettable Experiences.', 'Beyond Expectations.', 'Where Luxury Lives.'],
      typeSpeed: 50,
      loop: true,

    });


    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);


  const downloadApp = () => {
    window.open("https://radhakrishna-dining.en.uptodown.com/android", '_blank');
  };


  return (
    <>
      <div id="home">
        <div id='context'>
          <span id='starttxt'>Welcome to</span>
          <br />
          <h1 id='NameTitle'>
            Hotel <span id='NameTag'>राधाकृष्ण</span>
            <br />
            <span id='typedText' ref={el}></span>
          </h1>
          <br /><br />
          <p id='Description'>
            Download The Official Radhakrishna App
            to check Menu, Room Price and Give Review.
          </p>
          {/* Download Button For App */}
          <p style={{ marginTop: '16px' }} align='center'>
            <button class="button" type="button" onClick={downloadApp}>
              <span class="button__text">Download</span>
              <span class="button__icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" id="bdd05811-e15d-428c-bb53-8661459f9307" data-name="Layer 2" class="svg"><path d="M17.5,22.131a1.249,1.249,0,0,1-1.25-1.25V2.187a1.25,1.25,0,0,1,2.5,0V20.881A1.25,1.25,0,0,1,17.5,22.131Z"></path><path d="M17.5,22.693a3.189,3.189,0,0,1-2.262-.936L8.487,15.006a1.249,1.249,0,0,1,1.767-1.767l6.751,6.751a.7.7,0,0,0,.99,0l6.751-6.751a1.25,1.25,0,0,1,1.768,1.767l-6.752,6.751A3.191,3.191,0,0,1,17.5,22.693Z"></path><path d="M31.436,34.063H3.564A3.318,3.318,0,0,1,.25,30.749V22.011a1.25,1.25,0,0,1,2.5,0v8.738a.815.815,0,0,0,.814.814H31.436a.815.815,0,0,0,.814-.814V22.011a1.25,1.25,0,1,1,2.5,0v8.738A3.318,3.318,0,0,1,31.436,34.063Z"></path></svg></span>
            </button>
          </p>
        </div>
        <img height='400px' src={homeBuilding} id='imgsample' alt='Home Image' />
      </div>
    </>
  );
}

//About here
function About() {
  return (
    <>
      <div id='about'>
        <img id='aboutImg' height='400px' src={aboutImage} alt='About Image' />
        <div id='aboutContext'>
          <h3>About</h3>
          <h1>The Best Holidays Start Here !</h1>
          <p>
            With a focus on quality accommodations, personalized experiences, and
            seamless booking, our platform is dedicated to ensuring that every traveler
            embarks on their dream holiday with confidence and excitement.
          </p>
        </div>
      </div>

    </>
  );
}

//Service Section here
function Services() {
  return (
    <>
      <div id='services'>
        <div>
          <h5>Services</h5>
          <br />
          <h6>Strive Only For The Best.</h6>
        </div>
        <div id='serviceBox'>
          <div>
            <ServicesCard1 />
            <br /><br />
            <ServicesCard2 />
            <br /><br />
            <ServicesCard3 />
            <br /><br />
            <ServicesCard4 />
            <br /><br />
          </div>
        </div>
      </div>
    </>
  );
}

//1st Service card
function ServicesCard1() {

  return (
    <>
      <div class="container">
        <a></a>
        <a></a>
        <a></a>
        <a></a>
        <div class="card">
          <div id='serviceCardContain'>
            <img height='30px' src={security} alt='Security' />
            <h4 style={{ marginLeft: '10px' }}>High Class Security</h4>
          </div>
        </div>
      </div>
    </>
  );
}

//2nd Service card
function ServicesCard2() {

  return (
    <>
      <div class="container">
        <a></a>
        <a></a>
        <a></a>
        <a></a>
        <div class="card">
          <div id='serviceCardContain'>
            <img height='30px' src={hours} alt='24 Hours' />
            <h4 style={{ marginLeft: '10px' }}>24 Hours Room Service</h4>
          </div>
        </div>
      </div>
    </>
  );
}

//3rd Service card
function ServicesCard3() {

  return (
    <>
      <div class="container">
        <a></a>
        <a></a>
        <a></a>
        <a></a>
        <div class="card">
          <div id='serviceCardContain'>
            <img height='30px' src={cake} alt='Meeting Room' />
            <h4 style={{ marginLeft: '10px' }}>Celebration Halls</h4>
          </div>
        </div>
      </div>
    </>
  );
}

//4th Service card
function ServicesCard4() {

  return (
    <>
      <div class="container">
        <a></a>
        <a></a>
        <a></a>
        <a></a>
        <div class="card">
          <div id='serviceCardContain'>
            <img height='30px' src={wifi} alt='Tourist Guid' />
            <h4 style={{ marginLeft: '10px' }}>Wi-Fi connectivity</h4>
          </div>
        </div>
      </div>
    </>
  );
}

//Explore Cards Here for Multiple Values
const Card = ({ img, title, description }) => (
  <div class="card">
    <div class="card-image-container">
      <img src={img} height='100px' alt='images' />
    </div>
    <p class="card-title">{title}</p>
    <p class="card-des">{description}</p>
  </div>

);


//Explore Section Goes Here
function Explore() {

  //Card Data Goes Here
  const cardTitle1 = 'Garden For Kids';
  const cardDescription1 = 'Our one of the most quality services provides joy to your little loved ones.';
  const cardTitle2 = 'Large Dinings';
  const cardDescription2 = 'Experience urban elegance and modern comfort in the heart of the city.';
  const cardTitle3 = 'Tasty Food';
  const cardDescription3 = 'We provide tastefull experience for you family & friends.';
  const cardTitle4 = 'Room to Relax';
  const cardDescription4 = 'Experience luxury in our meticulously designed accommodations, ensuring your utmost comfort and relaxation.';
  const cardTitle5 = 'Personalized Hospitality';
  const cardDescription5 = 'Discover exceptional hospitality with our concierge services, tailoring every aspect of your stay to your preferences.';

  return (
    <>
      <div id='explore'>
        <div id='exploreContext'>
          <h6>Explore More</h6>
          <h4>The Most Memorable Rest</h4>
          <h4>Time Starts Here.</h4>
        </div>
        <div id='exploreCardBox'>
          {/* Fist Card */}
          <Card img={garden} title={cardTitle1} description={cardDescription1} />
          <Card img={dining} title={cardTitle2} description={cardDescription2} />
          <Card img={food} title={cardTitle3} description={cardDescription3} />
          <Card img={room} title={cardTitle4} description={cardDescription4} />
          <Card img={hospitality} title={cardTitle5} description={cardDescription5} />
        </div>
      </div>
    </>
  );
}

//Social Media Contact Here 
function Contact() {
  return (
    <>
      <div class="contactCard">
        <a class="social-link1" href='https://www.instagram.com/_hotel_radha_krushna_1111/' target='_blank'>
          <svg viewBox="0 0 16 16" class="bi bi-instagram" fill="currentColor" height="16" width="16" xmlns="http://www.w3.org/2000/svg" style={{ color: 'white' }}> <path fill="white" d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"></path> </svg>
        </a>
        <a class="social-link2" href='https://www.facebook.com/hotelradhakrushna' target='_blank'>
          <svg viewBox="0 0 50 50" class="bi bi-twitter" fill="currentColor" height="50" width="50" xmlns="http://www.w3.org/2000/svg" style={{ color: 'white' }}> <path fill="white" d="M40,0H10C4.486,0,0,4.486,0,10v30c0,5.514,4.486,10,10,10h30c5.514,0,10-4.486,10-10V10C50,4.486,45.514,0,40,0z M39,17h-3 c-2.145,0-3,0.504-3,2v3h6l-1,6h-5v20h-7V28h-3v-6h3v-3c0-4.677,1.581-8,7-8c2.902,0,6,1,6,1V17z"></path> </svg>              </a>

        <a class="social-link4" href='https://wa.me/+919850762385?text=Hello' target='_blank'>
          <svg viewBox="0 0 16 16" class="bi bi-whatsapp" fill="currentColor" height="16" width="16" xmlns="http://www.w3.org/2000/svg" style={{ color: 'white' }}> <path fill="white" d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"></path> </svg>
        </a>
      </div>
    </>
  );
}

//Creating Simple Footer
function Footer() {
  return (
    <>
      <footer id='footer'>
        <div id='footerContext'>
          <img height='110px' src={logo} alt='logo' />
          <p>Rahuri-Shani Shingnapur Road,
            <br />
            Umbare, Tal-Rahuri, Dist-A. Nagar.
            <br /><br />
            S.B. Chaudhari
            <br />
            9850762385
          </p>
        </div>
        <div id='footerContact'>
          <h4>Contact Us</h4>
          <Contact />
        </div>

      </footer>
      <div id='credits'>
        <p>Cpoyright &copy; 2024 Team The 7.</p>
      </div>
    </>
  );
}


function ReviewSection() {

  const [displayCount, setDisplayCount] = useState(5); // Initial number of reviews to display

  const handleShowMore = () => {
    setDisplayCount(displayCount + 5); // Increase display count by 5 when "Show More" is clicked
  };

  return (
    <>
      <h1 style={{ marginTop: '30px' }} id='reviewHeading'>Reviews</h1>
      <h6 id='reviewSubheading'>Where Every Opinion Matters</h6>
      <div style={{ marginTop: '15px' }} id='reviewSection'>
        <div id='reviews'>
          <Reviews displayCount={displayCount} />
        </div>
        <div id='reviewForm'>
          <ReviewForm />
        </div>
      </div>

      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:'22px' }}>
        {displayCount < 20 && ( // Show "Show More" button if not all reviews are displayed


          <button class="cssbuttons-io-button" onClick={handleShowMore}>
            Show More Reviews
            <div class="icon">
              <svg
                id='svg1'
                height="24"
                width="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 0h24v24H0z" fill="none"></path>
                <path
                  d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          </button>

        )}

      </div>

    </>
  );
}


function ReviewForm() {

  const [rating, setRating] = useState(0);
  const logoutbtn = document.getElementById('button');

  const handleStarClick = (starIndex) => {
    setRating(starIndex + 1);
  };

  const [formData, setFormData] = useState({
    userRating: 0,
    message: '',
    email: '',
  });

  const [user, setUser] = useState(null);


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();


    const user = auth.currentUser;
    logoutbtn.style.display = 'none';

    //Checking user data
    if (rating === 0 || formData.message.trim() === '') {
      alert('Please select a rating and provide a message.');
      return;
    } else {
      logoutbtn.style.display = 'flex';

      //checking user log in

      if (!user) {
        alert('Please sign in with Google.');
        logoutbtn.style.display = 'none';
        handleSignIn()
        return;
      }


      // Get user's email address
      const email = user.email;

      // Get a reference to the database
      const database = getDatabase();

      // Push data to the 'Reviews' node
      const reviewsRef = ref(database, 'Hotel/Review/UserReview/'+user.uid);
      const newData = { ...formData, userRating: rating, email: email }; // Include user rating in form data
      set(reviewsRef, newData);

      // Reset the form data
      setFormData({
        userRating: '',
        message: '',
        email: '',
      });

      // Optionally, you can reset the star rating
      setRating(0);

    }


  };

  //Sign in user 
  const handleSignIn = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // Handle successful sign-in
        const user = result.user;
        console.log('Sign-in successful:', user.displayName);
      })
      .catch((error) => {
        // Handle errors
        console.error('Sign-in error:', error.message);
      });
  };


  //Log out user
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
        console.log('User logged out successfully');
      })
      .catch((error) => {
        console.error('Error logging out:', error.message);
      });
  };


  return (
    <div id='formContainer'>
      <div className="form-container">
        <form className="form" method='POST' onSubmit={handleSubmit}>
          <div id='starContainer'>
            {[...Array(5)].map((_, index) => (
              <span
                key={index}
                className={`star ${index < rating ? 'filled' : ''}`}
                onClick={() => handleStarClick(index)}>
                &#9733;
              </span>
            ))}
          </div>
          <div className="form-group">
            <label htmlFor="textarea">Write Your Review</label>
            <textarea
              placeholder='Write Your Experience ...'
              name="message"
              id="textarea"
              rows="10"
              cols="50"
              required=""
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>
          <div id='btnDiv'>
            <button className="form-submit-btn" type="submit">Submit</button>
            {/* Log out Button */}
            <button id='button' onClick={handleLogout}>
              <span>Logout</span>
              <svg width="34" height="34" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="37" cy="37" r="35.5" stroke="black" stroke-width="3"></circle>
                <path d="M25 35.5C24.1716 35.5 23.5 36.1716 23.5 37C23.5 37.8284 24.1716 38.5 25 38.5V35.5ZM49.0607 38.0607C49.6464 37.4749 49.6464 36.5251 49.0607 35.9393L39.5147 26.3934C38.9289 25.8076 37.9792 25.8076 37.3934 26.3934C36.8076 26.9792 36.8076 27.9289 37.3934 28.5147L45.8787 37L37.3934 45.4853C36.8076 46.0711 36.8076 47.0208 37.3934 47.6066C37.9792 48.1924 38.9289 48.1924 39.5147 47.6066L49.0607 38.0607ZM25 38.5L48 38.5V35.5L25 35.5V38.5Z" fill="black"></path>
              </svg>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}


function ReviewCard({ review }) {

  const filledStars = [...Array(review.userRating)].map((_, index) => (
    <span key={index} className='reviewCardRating filled'>&#9733;</span>
  ));

  const emptyStars = [...Array(5 - review.userRating)].map((_, index) => (
    <span key={index} className='reviewCardRating'>&#9733;</span>
  ));


  return (
    <>
      <div class="notification">
        <div class="notiglow"></div>
        <div class="notiborderglow"></div>
        <div class="notititle">{review.email}</div>
        <div id='cardRating'>
          {filledStars}
          {emptyStars}
        </div>
        <div class="notibody">{review.message}</div>
      </div>
    </>
  );
}

function Reviews({ displayCount }) {

  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const database = getDatabase();
    const user1 = auth.currentUser;
    const reviewsRef = ref(database, 'Hotel/Review/UserReview/');

    // Listen for changes to the reviews data
    onValue(reviewsRef, (snapshot) => {
      const reviewsData = snapshot.val();
      if (reviewsData) {
        const reviewsList = Object.values(reviewsData);
        setReviews(reviewsList);
      }
    });

    // Clean up listener on unmount
    return () => {
      // Detach the event listener when the component unmounts
      // This prevents memory leaks and potential performance issues
      setReviews([]);
    };
  }, []);

  return (
    <>
      {reviews.slice(0, displayCount).map((review, index) => (
        <ReviewCard key={index} review={review} />
      ))}
    </>
  );

}

export default App;
