// firebaseConfig.js
const firebaseConfig = {
    apiKey: "AIzaSyCjFD0JhvgjX-TmnIDP657Ak_f8q6C5gCw",
    authDomain: "hotel-radhakrishna-70f7a.firebaseapp.com",
    databaseURL: "https://hotel-radhakrishna-70f7a-default-rtdb.firebaseio.com",
    projectId: "hotel-radhakrishna-70f7a",
    storageBucket: "hotel-radhakrishna-70f7a.appspot.com",
    messagingSenderId: "1009476396760",
    appId: "1:1009476396760:web:bb6168c6d9ae76265e19ad",
    measurementId: "G-7VFSJ1SQKN"
  };
  
  export default firebaseConfig;
  